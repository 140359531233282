<template>
  <div>
    <left-and-right>
      <div slot="leftDiv">
        <div class="content">
          <ul>
            <li v-for="i in data.records" :key="i.id" @click="toDetails(i.id)">
              <div class="line">
                <p class="title"><span>&bull;</span>{{ i.title }}</p>
                <p class="time">{{ i.time }}</p>
              </div>
              <p class="text">
                {{ i.brief }}
              </p>
              <p class="btn">查看详情 >>></p>
            </li>
          </ul>
        </div>
        <pagination v-on:toPage="toPage" :this-page="current" :pages="data.pages"></pagination>
      </div>
      <div slot="rightDiv">
        <card ref="card">
          <div slot="heard" class="search">
            <input placeholder="搜索">
            <i><img src="../../assets/image/icon/search.png"></i>
          </div>
        </card>
        <img src="../../assets/image/web.png">
      </div>
    </left-and-right>
  </div>
</template>

<script>
import leftAndRight from "@/components/public/leftAndRight";
import card from "@/components/public/card";
import pagination from "@/components/public/pagination";

export default {
  name: "report",
  components: {
    leftAndRight,
    card,
    pagination
  },
  data() {
    return {
      data: {},
      current: 1,

    }
  },
  mounted() {
    this.getDataList()
  },
  methods: {
    getDataList() {
      this.$http.get('/safe/getArticleList', {
        params: {
          columnName: '行业报告',
          belong: 1,
          current: this.current,
          size: 10
        }
      }).then((res) => {
        let forms = res.data.records
        for (let i in forms) {
          let pic = forms[i].pic
          if (pic != null) {
            forms[i].pic = this.$constContent.imgUrl + pic
          }
          let time = forms[i].time;
          let date = new Date(time);
          forms[i].time = this.$constContent.dateFormat("YYYY-mm-dd HH:MM", date)
        }
        this.data = res.data
      })

      const ths = this
      this.$http.get('/safe/getIndexInformation', {
        params: {
          name: '往期回顾',
        }
      }).then((res) => {
        let forms = res.data

        for (let i in forms) {
          let pic = forms[i].pic
          if (pic != null) {
            forms[i].pic = this.$constContent.imgUrl + pic
          }
          let time = forms[i].time;
          let date = new Date(time);
          res.data[i].time = this.$constContent.dateFormat("YYYY-mm-dd HH:MM", date)
        }
        let Cards = []
        let Card = {}
        Card.title = '往期回顾'
        Card.id = 0
        Card.content = res.data
        Cards.push(Card)
        ths.$nextTick(() => {
          ths.$refs.card.init(Cards)
        })
      })
    },
    toPage(e) {
      this.current = e
      this.getDataList()
    },
    toDetails(id) {
      this.$router.push('/literatureDetails?id=' + id)
      this.$emit('getTitle', '文献详情')
    }
  }
}
</script>

<style scoped>
.content {
  width: 100%;
  display: block;
}

ul {
  list-style: none;
  padding-left: 0;
}

ul li {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  border-bottom: 1px solid #eeeeee;

}

.line {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}

ul li p {
  font-size: 14px;
  color: #666666;
  cursor: pointer;
}

.title {
  width: 60%;
  font-weight: 800;
  font-size: 16px;
  color: #050001;
}

.title span {
  margin-right: 10px;
}

.text {
  width: calc(100% - 15px);
  padding-left: 15px;
  line-height: 24px;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.btn {
  width: 20%;
  text-align: right;
  margin: 10px 0 10px 80%;
  float: right;
  font-size: 14px;
  color: #666666;
}

.time {
  width: 30%;
  padding-right: 10px;
  text-align: right;
}


.search {
  width: 100%;
  height: 40px;
}

.search input {
  height: 38px;
  width: calc(100% - 20px);
  border-radius: 4px;
  padding: 0 10px;
  border: 1px solid #dfdfdf;
}

.search i img {
  width: 15px;
  height: 15px;
  position: absolute;
  top: 12px;
  right: 12px;
}

img {
  width: 100%;
}
</style>